import { Box, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Fragment, useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  AddPatient,
  deletePatient,
  getPatient,
  updatePatient,
} from "../../Redux/Patient/PatientActions";
import Header from "../../components/Header";
import Loading from "../../components/Loading";
import { tokens } from "../../theme";
import "./../Style.css";
import Sidebar from "./../global/Sidebar";
import Topbar from "./../global/Topbar";
import { getScanner } from "../../Redux/Scanner/ScannerActions";
import isAuth from "../../scenes/Utils/isAuth";
import { getDistrict } from "../../Redux/District/DistrictActions";
import { getplan } from "./../../Redux/Plan/PlanActions";
import { getClinic } from "../../Redux/Clinic/ClinicActions";
import { getDesigners } from "../../Redux/Designer/DesignerActions";
import { getPrinters } from "../../Redux/Printer/PrinterActions";
import { NotificationContainer } from "react-notifications";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
const Patients = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { plan } = useSelector((state) => state.Plan);

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/login");
  }, [dispatch, navigate]);
  const { Patient, loading, error, addPatient } = useSelector(
    (state) => state.Patient
  );
  const { Clinic } = useSelector((state) => state.Clinic);
  const { Scanner } = useSelector((state) => state.Scanner);
  const { designer } = useSelector((state) => state.Designer);
  const { printer } = useSelector((state) => state.Printer);

  useEffect(() => {
    dispatch(getPatient());
    dispatch(getDistrict());
    dispatch(getClinic());
    dispatch(getScanner());
    dispatch(getDesigners());
    dispatch(getPrinters());
    dispatch(getplan());
  }, []);
  const [dataplan, setDataplan] = useState([]);
  const [dataclinic, setDataclinic] = useState([]);

  useEffect(() => {
    if (plan) {
      setDataplan(plan?.data?.plans);
    }
  }, [plan]);
  useEffect(() => {
    if (Clinic) {
      setDataclinic(Clinic?.data?.clinics);
    }
  }, [Clinic]);
  console.log(dataplan);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (Patient) {
      setData(Patient?.data?.patients);
    }
  }, [Patient]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [id, setId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [teethModel, setTeethModel] = useState("");
  const [treatmentPlan, setTreatmentPlan] = useState("");
  const [numberOfAligners, setNumberOfAligners] = useState("");
  const [currentAligner, setCurrentAligner] = useState("");
  const [timerStartDate, setTimerStartDate] = useState("");
  const [treatmentEnded, setTreatmentEnded] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("");
  const [sourceId, setSourceId] = useState("");
  const [statusId, setStatusId] = useState("");
  const [stageId, setStageId] = useState("");
  const [clinicId, setClinicId] = useState("");
  const [designerId, setDesignerId] = useState("");
  const [scannerId, setScannerId] = useState("");
  const [printerId, setPrinterId] = useState("");
  const [remainingAmount, setRemainingAmount] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [patients, setPatients] = useState([]);
  const [isclicked, setIsclicked] = useState(false);
  const [plan_id, setPlanId] = useState("");
  const [disabled, setDisabled] = useState(false);

  const deleteplanhandle = (id) => {
    dispatch(deletePatient(id));
    setModalIsOpen(false);
    NotificationManager.success(
      "Success",
      "Patient Deleted Successfully",
      5000,
      () => {}
    );
    setId("");
  };
  const [district_id, setDistrictId] = useState("");
  const { District } = useSelector((state) => state.District);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const [addmodalIsOpen, setaddModalIsOpen] = useState(false);

  const addopenModal = () => {
    setaddModalIsOpen(true);
  };

  const addcloseModal = () => {
    setaddModalIsOpen(false);
    setedutModalIsOpen(false);
    clearForm();
  };
  const addplanhandle = () => {
    setIsclicked(true);
    dispatch(
      AddPatient(
        email,
        phoneNumber,
        parseInt(district_id),
        parseInt(plan_id),
        patients
      )
    );
  };
  console.log("error", error);
  console.log("addPatient", addPatient);

  const [edutmodalIsOpen, setedutModalIsOpen] = useState(false);

  const editopenModal = () => {
    setedutModalIsOpen(true);
  };

  const editcloseModal = () => {
    setedutModalIsOpen(false);
    clearForm();
  };
  const editPatienthandle = () => {
    dispatch(
      updatePatient(
        parseInt(id),
        firstName,
        lastName,
        age,
        gender,
        teethModel,
        parseInt(numberOfAligners),
        parseInt(currentAligner),
        timerStartDate,
        treatmentEnded,
        parseInt(paymentAmount),
        parseInt(statusId),
        parseInt(stageId),
        parseInt(clinicId),
        parseInt(designerId),
        parseInt(scannerId),
        parseInt(printerId),
        parseInt(remainingAmount),
        parseInt(treatmentEnded)
      )
    );
    setedutModalIsOpen(false);
    clearForm();
    setId("");
  };

  const handleAddPatient = () => {
    setPatients([
      ...patients,
      { first_name: "", last_name: "", gender: "male", age: "" },
    ]);
  };

  const handlePatientChange = (index, field, value) => {
    const updatedPatients = [...patients];
    updatedPatients[index][field] = value;
    setPatients(updatedPatients);
  };
  const handleDeletePatient = (index) => {
    const updatedPatients = [...patients];
    updatedPatients.splice(index, 1);
    setPatients(updatedPatients);
  };

  const clearForm = () => {
    setFirstName("");
    setLastName("");
    setAge("");
    setGender("");
    setTeethModel("");
    setTreatmentPlan("");
    setNumberOfAligners("");
    setCurrentAligner("");
    setTimerStartDate("");
    setTreatmentEnded("");
    setPaymentAmount("");
    setSourceId("");
    setStatusId("");
    setStageId("");
    setClinicId("");
    setDesignerId("");
    setScannerId("");
    setPrinterId("");
    setRemainingAmount("");
    setPatients([]);
    setEmail("");
    setPhoneNumber("");
    setDistrictId("");
    setPlanId("");
  };

  useEffect(() => {
    if (error !== null && isclicked === true) {
      NotificationManager.error("Error", error?.message, 5000, () => {});
    } else if (addPatient == "success" && isclicked === true) {
      NotificationManager.success(
        "Success",
        "Patient Added Successfully",
        5000,
        () => {}
      );
      setaddModalIsOpen(false);
      setEmail("");
      setPhoneNumber("");
      setDistrictId("");
      setPlanId("");
      setPatients([]);
      setIsclicked(false);
    }
  }, [error, addPatient]);

  // eslint-disable-next-line no-sparse-arrays
  const columns = [
    { field: "id", headerName: "Treatment ID", minwidth: 200 },

    {
      field: "Patient Name",
      headerName: "Patient Name",
      className: "name-column--cell",
      width: 150,
      renderCell: (params) => (
        <div>
          {params.row?.first_name} {params.row?.last_name}
        </div>
      ),
    },
    {
      field: "Clinic",
      headerName: "Clinic",
      className: "name-column--cell",
      flex: 1,
      renderCell: (params) => <div>{params.row.clinic?.name}</div>,
    },

    { field: "scanner_id", headerName: "Scanner ID", minwidth: 100 },
    { field: "printer_id", headerName: "Printer ID", minwidth: 100 },
    { field: "designer_id", headerName: "Designer ID", minwidth: 100 },
    {
      field: "timer_start_date",
      headerName: "timer_start_date",
      width: 200,
    },
    {
      field: "treatment_ended",
      headerName: "Treatment Ended",
      width: 200,
      valueFormatter: (params) => (params.value === 1 ? "true" : "false"),
    },

    { field: "payment_amount", headerName: "Payment", width: 200 },
    {
      field: "remaining_amount",
      headerName: "Remaining Payment Amount",
      width: 200,
    },

    {
      field: "number_of_aligners",
      headerName: "number_of_aligners",
      flex: "auto",
    },
    { field: "current_aligner", headerName: "current_aligner", flex: "auto" },

    {
      field: "Phone number",
      headerName: "Phone number",
      className: "name-column--cell",
      width: 100,
      renderCell: (params) => <div>{params.row.user?.phone_number}</div>,
    },

    {
      field: "source_id",
      headerName: "Source",
      renderCell: (params) => {
        // Assuming params.row.source_id is the actual value from your data
        const sourceId = params.row.source_id;

        return (
          <span style={{ color: sourceId === 1 ? "red" : "blue" }}>
            {sourceId === 1 ? "Fixalign" : "Clinic"}
          </span>
        );
      },
    },
    {
      field: "status_id",
      headerName: "Status",
      renderCell: (params) => {
        const stageId = params.row.status_id;

        return (
          <span>
            {stageId === 1
              ? "On-going"
              : stageId === 2
              ? "Finished"
              : "Pending"}
          </span>
        );
      },
    },
    {
      field: "stage_id",
      headerName: "Stage ID",
      renderCell: (params) => {
        const stageId = params.row.stage_id;

        return (
          <span>
            {stageId === 1
              ? "Check In "
              : stageId === 2
              ? "Scan "
              : stageId === 3
              ? "Treatment plan"
              : stageId === 4
              ? "3D design "
              : stageId === 5
              ? "Production"
              : stageId === 6
              ? "Delivery"
              : "Handover"}
          </span>
        );
      },
    },
    {
      field: "Treatment Plan",
      headerName: "Treatment Plan",
      width: 200,
      renderCell: (record) => {
        return (
          <Fragment>
            <button
              variant="outlined"
              color="primary"
              onClick={() =>
                window.open(
                  process.env.REACT_APP_API +
                    record?.row?.user_treatments?.treatment_pdf,
                  "_blank"
                )
              }
            >
              Open Treatment Plan
            </button>
          </Fragment>
        );
      },
    },

    {
      field: "Teeth Model",
      headerName: "Teeth Model",
      width: 200,
      renderCell: (record) => {
        return (
          <Fragment>
            <button
              variant="outlined"
              color="primary"
              onClick={() => window.open(record.row.teeth_model, "_blank")}
            >
              Open Teeth Modal
            </button>
          </Fragment>
        );
      },
    },
    {
      field: "calendar",
      headerName: "Calendar",

      width: 250,
      renderCell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#delete-carousel-modal"
              className="actions3"
              onClick={() => {
                navigate(
                  `/File/${record.row.id}?name=${
                    record.row.first_name + " " + record.row.last_name
                  }`
                );
              }}
            >
              View Patient Files
            </button>
          </Fragment>
        );
      },
    },
    {
      field: "Treatment",
      headerName: "Treatment",

      width: 350,
      renderCell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#delete-carousel-modal"
              className="actions31"
              onClick={() => {
                navigate(
                  `/Treatment/${record.row.id}?name=${
                    record.row.first_name + " " + record.row.last_name
                  }`
                );
              }}
            >
              View Patient Scans and Treatment
            </button>
          </Fragment>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,

      renderCell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#update-carousel-modal"
              className="actions"
              style={{ marginRight: "5px" }}
              onClick={() => {
                setId(record.row.id);
                setFirstName(record.row.first_name);
                setLastName(record.row.last_name);
                setAge(record.row.age);
                setGender(record.row.gender);
                setTeethModel(record.row.teeth_model);
                setNumberOfAligners(record.row.number_of_aligners);
                setCurrentAligner(record.row.current_aligner);
                setTimerStartDate(record.row.timer_start_date);
                setTreatmentEnded(record.row.treatment_ended);
                setPaymentAmount(record.row.payment_amount);
                setSourceId(record.row.source_id);
                setStatusId(record.row.status_id);
                setStageId(record.row.stage_id);
                setClinicId(record.row.clinic_id);
                setDesignerId(record.row.designer_id);
                setScannerId(record.row.scanner_id);
                setPrinterId(record.row.printer_id);
                setRemainingAmount(record.row.remaining_amount);

                editopenModal();
              }}
            >
              Edit
            </button>
            {localStorage.getItem("role") == "superadmin" && (
              <button
                data-toggle="modal"
                data-target="#delete-carousel-modal"
                className="actions1"
                onClick={() => {
                  setId(record.row.id);
                  openModal();
                }}
              >
                Delete
              </button>
            )}
          </Fragment>
        );
      },
    },
  ];
  const [isSidebar, setIsSidebar] = useState(true);
  useEffect(() => {
    if (patients.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [patients]);
  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />
        <Box m="20px">
          <Modal
            isOpen={edutmodalIsOpen}
            onRequestClose={editcloseModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="Edit modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                width: "40%",

                margin: "0 auto",
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
              },
            }}
          >
            <div>
              <h2 className="modaltitle">Edit patient</h2>
              {(localStorage.getItem("role") == "admin" ||
                localStorage.getItem("role") == "superadmin" ||
                localStorage.getItem("role") == "clinic") && (
                <form>
                  <div className="modal-body modalview">
                    <div className="row formRow inputstyle">
                      <h6>First Name:</h6>
                      <input
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="Enter First Name"
                        required
                      />
                    </div>

                    <div className="row formRow inputstyle">
                      <h6>Last Name:</h6>
                      <input
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        placeholder="Enter Last Name"
                        required
                      />
                    </div>

                    <div className="row formRow inputstyle">
                      <h6>Age:</h6>
                      <input
                        type="number"
                        value={age}
                        onChange={(e) => setAge(e.target.value)}
                        placeholder="Enter Age"
                        required
                      />
                    </div>

                    <div className="row formRow inputstyle ">
                      <h6>Gender:</h6>
                      <select
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                        required
                        className="selectg"
                      >
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>
                    <div className="row formRow inputstyle">
  <label style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
    <input
      type="checkbox"
      checked={treatmentEnded === 1}
      onChange={(e) => setTreatmentEnded(e.target.checked ? 1 : 0)}
    />
    <span>Treatment Ended:</span>
  </label>
</div>


                    <div className="row formRow inputstyle">
                      <h6>Teeth Model:</h6>
                      <input
                        type="text"
                        value={teethModel}
                        onChange={(e) => setTeethModel(e.target.value)}
                        placeholder="Enter Teeth Model"
                      />
                    </div>

                    <div className="row formRow inputstyle">
                      <h6>Number of Aligners:</h6>
                      <input
                        type="number"
                        value={numberOfAligners}
                        onChange={(e) => setNumberOfAligners(e.target.value)}
                        placeholder="Enter Number of Aligners"
                      />
                    </div>

                    <div className="row formRow inputstyle">
                      <h6>Current Aligner:</h6>
                      <input
                        type="text"
                        value={currentAligner}
                        onChange={(e) => setCurrentAligner(e.target.value)}
                        placeholder="Enter Current Aligner"
                      />
                    </div>

                    <div className="row formRow inputstyle">
                      <h6>Timer Start Date:</h6>
                      <input
                        type="datetime-local"
                        value={timerStartDate}
                        onChange={(e) => setTimerStartDate(e.target.value)}
                        placeholder="Enter Timer Start Date"
                      />
                    </div>

                    <div className="row formRow inputstyle">
                      <h6>Payment Amount:</h6>
                      <input
                        type="number"
                        value={paymentAmount}
                        onChange={(e) => setPaymentAmount(e.target.value)}
                        placeholder="Enter Payment Amount"
                      />
                    </div>
                    <div className="row formRow inputstyle">
                      <h6>Remaining Amount:</h6>
                      <input
                        type="number"
                        value={remainingAmount}
                        onChange={(e) => setRemainingAmount(e.target.value)}
                        placeholder="Enter Remaining Amount"
                      />
                    </div>
                    <div className="row formRow inputstyle">
                      <h6>Status:</h6>
                      <select
                        value={statusId}
                        onChange={(e) => setStatusId(e.target.value)}
                        required
                        className="selectg"
                      >
                        <option value="">Select Status</option>
                        <option value="1">On-going</option>
                        <option value="2">Finished</option>
                        <option value="3">Pending</option>
                      </select>
                    </div>

                    <div className="row formRow inputstyle">
                      <h6>Stage:</h6>
                      <select
                        value={stageId}
                        onChange={(e) => setStageId(e.target.value)}
                        required
                        className="selectg"
                      >
                        <option value="">Select Stage</option>
                        <option value="1">Check In</option>
                        <option value="2">Scan</option>
                        <option value="3">Treatment Plan</option>
                        <option value="4">3D Design</option>
                        <option value="5">Production</option>
                        <option value="6">Delivery</option>
                        <option value="7">Handover</option>
                      </select>
                    </div>

                    <div className="row formRow inputstyle">
                      <h6>Clinic :</h6>

                      <select
                        onChange={(e) => setClinicId(e.target.value)}
                        required
                        defaultValue={clinicId}
                        className="selectg"
                      >
                        <option value="" className="option">
                          Please select Clinic
                        </option>
                        {dataclinic?.map((brands) => (
                          <option key={brands.id} value={brands.id}>
                            {brands.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="row formRow inputstyle">
                      <h6>Designer :</h6>

                      <select
                        onChange={(e) => setDesignerId(e.target.value)}
                        required
                        defaultValue={designerId}
                        className="selectg"
                      >
                        <option value="" className="option">
                          Please select Designer
                        </option>
                        {designer?.data?.designers?.map((brands) => (
                          <option key={brands.id} value={brands.id}>
                            {brands.first_name} {brands.last_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="row formRow inputstyle">
                      <h6>Scanner :</h6>

                      <select
                        onChange={(e) => setScannerId(e.target.value)}
                        required
                        defaultValue={scannerId}
                        className="selectg"
                      >
                        <option value="" className="option">
                          Please select Scanner
                        </option>
                        {Scanner?.data?.scanners?.map((brands) => (
                          <option key={brands.id} value={brands.id}>
                            {brands.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="row formRow inputstyle">
                      <h6>Printer :</h6>

                      <select
                        onChange={(e) => setPrinterId(e.target.value)}
                        required
                        defaultValue={printerId}
                        className="selectg"
                      >
                        <option value="" className="option">
                          Please select Printer
                        </option>
                        {printer?.data?.printers.map((brands) => (
                          <option key={brands.id} value={brands.id}>
                            {brands.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </form>
              )}
              {localStorage.getItem("role") == "lab" && (
                <form>
                  <div className="modal-body modalview">
                    <div className="row formRow inputstyle">
                      <h6>Teeth Model:</h6>
                      <input
                        type="text"
                        value={teethModel}
                        onChange={(e) => setTeethModel(e.target.value)}
                        placeholder="Enter Teeth Model"
                      />
                    </div>
                    <div className="row formRow inputstyle">
                      <h6>Number of Aligners:</h6>
                      <input
                        type="number"
                        value={numberOfAligners}
                        onChange={(e) => setNumberOfAligners(e.target.value)}
                        placeholder="Enter Number of Aligners"
                      />
                    </div>
                    <div className="row formRow inputstyle">
                      <h6>Stage:</h6>
                      <select
                        value={stageId}
                        onChange={(e) => setStageId(e.target.value)}
                        required
                        className="selectg"
                      >
                        <option value="">Select Stage</option>
                        <option value="1">Check In</option>
                        <option value="2">Scan</option>
                        <option value="3">Treatment Plan</option>
                        <option value="4">3D Design</option>
                        <option value="5">Production</option>
                        <option value="6">Delivery</option>
                        <option value="7">Handover</option>
                      </select>
                    </div>

                    <div className="row formRow inputstyle">
                      <h6>Designer :</h6>

                      <select
                        onChange={(e) => setDesignerId(e.target.value)}
                        required
                        defaultValue={designerId}
                        className="selectg"
                      >
                        <option value="" className="option">
                          Please select Designer
                        </option>
                        {designer?.data?.designers?.map((brands) => (
                          <option key={brands.id} value={brands.id}>
                            {brands.first_name} {brands.last_name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="row formRow inputstyle">
                      <h6>Printer :</h6>

                      <select
                        onChange={(e) => setPrinterId(e.target.value)}
                        required
                        defaultValue={printerId}
                        className="selectg"
                      >
                        <option value="" className="option">
                          Please select Printer
                        </option>
                        {printer?.data?.printers.map((brands) => (
                          <option key={brands.id} value={brands.id}>
                            {brands.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </form>
              )}
            </div>
            <div
              style={{
                height: "4rem",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <button onClick={editcloseModal} className="buttoninmodal">
                Close
              </button>
              <button
                onClick={() => editPatienthandle()}
                className="buttoninmodal1"
              >
                Edit Patient
              </button>
            </div>
          </Modal>
          <Modal
            isOpen={addmodalIsOpen}
            onRequestClose={addcloseModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="Add modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                width: "40%",
                margin: "0 auto",
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
              },
            }}
          >
            <div
              style={{
                display: "contents",
              }}
            >
              {" "}
              <form>
                <h2 className="modaltitle">Add Patient</h2>

                <div className="modal-body modalview">
                  <div className="row formRow inputstyle">
                    <h6>Email:</h6>
                    <input
                      type="email"
                      className="inputt col-md-8 form-control cat"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="row formRow inputstyle">
                    <h6>Phone Number:</h6>
                    <input
                      type="tel"
                      className="inputt col-md-8 form-control cat"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                  <div className="row formRow inputstyle">
                    <h6>District:</h6>

                    <select
                      onChange={(e) => setDistrictId(e.target.value)}
                      required
                      defaultValue={district_id}
                      className="selectg"
                    >
                      <option value="" disabled className="option">
                        Please select District
                      </option>
                      {District?.data?.districts?.map((brands) => (
                        <option key={brands.id} value={brands.id}>
                          {brands.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="row formRow inputstyle">
                    <h6>Plan :</h6>

                    <select
                      onChange={(e) => setPlanId(e.target.value)}
                      required
                      defaultValue={plan_id}
                      className="selectg"
                    >
                      <option value="" disabled className="option">
                        Please select Plan
                      </option>
                      {dataplan?.map((brands) => (
                        <option key={brands.id} value={brands.id}>
                          {brands.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <h6>Patients:</h6>

                  <div className="patients-section">
                    {patients.map((patient, index) => (
                      <div key={index} className="patiend-add">
                        <input
                          type="text"
                          value={patient.first_name}
                          onChange={(e) =>
                            handlePatientChange(
                              index,
                              "first_name",
                              e.target.value
                            )
                          }
                          placeholder="First Name"
                          className="inputt col-md-8 form-control cat"
                        />
                        <input
                          type="text"
                          value={patient.last_name}
                          onChange={(e) =>
                            handlePatientChange(
                              index,
                              "last_name",
                              e.target.value
                            )
                          }
                          placeholder="Last Name"
                          className="inputt col-md-8 form-control cat"
                        />
                        <select
                          value={patient.gender}
                          onChange={(e) =>
                            handlePatientChange(index, "gender", e.target.value)
                          }
                          className="inputt col-md-8 form-control cat"
                        >
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="others">Others</option>
                        </select>
                        <input
                          type="number"
                          value={patient.age}
                          onChange={(e) =>
                            handlePatientChange(index, "age", e.target.value)
                          }
                          placeholder="Age"
                          className="inputt col-md-8 form-control cat"
                        />
                        <button
                          type="button"
                          onClick={() => handleDeletePatient(index)}
                        >
                          Delete
                        </button>
                      </div>
                    ))}
                    <button type="button" onClick={handleAddPatient}>
                      Add Patient
                    </button>
                  </div>
                </div>
              </form>
              <div
                style={{
                  height: "4rem",
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                  marginTop: "20px",
                }}
              >
                <button onClick={addcloseModal} className="buttoninmodal">
                  Close
                </button>
                <button
                  type="submit"
                  className="buttoninmodal1"
                  onClick={addplanhandle}
                  disabled={disabled}
                  style={{
                    backgroundColor: disabled ? "gray" : "red",
                    borderColor: disabled ? "gray" : "red",
                  }}
                >
                  Add Patient
                </button>
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="delete modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                width: "40rem",
                height: "15rem",
                margin: "0 auto",
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
              },
            }}
          >
            <div>
              <h2 className="modaltitle">Delete Patient</h2>
              <h5>Are You Sure You Want To Delete This Patient ?</h5>
            </div>
            <div
              style={{
                height: "4rem",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <button onClick={closeModal} className="buttoninmodal">
                Close
              </button>
              <button
                onClick={() => deleteplanhandle(id)}
                className="buttoninmodal1"
              >
                Delete
              </button>
            </div>
          </Modal>
          {localStorage.getItem("role") !== "lab" && (
            <Header
              title="Patient"
              buttonText="Add Patient"
              onopen={addopenModal}
              showboth={true}
            />
          )}
          {localStorage.getItem("role") == "lab" && (
            <Header title="Patient" show={true} showboth={true} />
          )}

          <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                zIndex: 0,
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
          >
            <NotificationContainer />

            {loading && <Loading />}
            {!loading && data && (
              <DataGrid
                rows={data}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
                sx={{
                  "& .MuiDataGrid-cell": {
                    fontSize: "16px", // Adjust the font size as needed
                    color: "black",
                  },
                  "& .MuiDataGrid-columnHeader": {
                    fontSize: "16px",
                    color: "black",
                    fontWeight: "bold", // Adjust the font size as needed
                  },
                }}
                checkboxSelection={true}
              />
            )}
          </Box>
        </Box>
      </main>
    </div>
  );
};

export default Patients;
