import axios from "axios";
import { PatientAction } from "./PatientReducers";
import { formatDate } from "@fullcalendar/core";

export const getPatient = () => async (dispatch) => {
  const token = localStorage.getItem("jwtToken");

  try {
    dispatch(PatientAction.PatientRequest());

    const res = await axios.get(process.env.REACT_APP_API + "/api/patients", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch(PatientAction.PatientSuccess(res));
  } catch (error) {
    dispatch(PatientAction.PatientFail(error));
  }
};
export const AddPatient = (email,phone_number,district_id,plan_id,patients) => async (dispatch) => {
  dispatch(PatientAction.addPatientRequest());
  const token = localStorage.getItem("jwtToken");
  const role = localStorage.getItem("role");
  const apirequest = role == "clinic" ? "/api/create-patient" : "/api/take-appointment";
  try {
    await axios.post(
      process.env.REACT_APP_API + apirequest,
      {
        "info": {
          "email": email,
          "phone_number": phone_number,
          "district_id": district_id,
          "plan_id": plan_id,


        },
        "patients": patients
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(PatientAction.addPatientSuccess("success"));
    dispatch(getPatient());
  } catch (e) {
    dispatch(PatientAction.addPatientFail(e.response.data));
  }
};

export const updatePatient = (
  id,
    first_name,
    last_name,
    age,
    gender,
    teeth_model,
    number_of_aligners,
    current_aligner,
    timer_start_date,
    treatment_ended,
    payment_amount,
    status_id,
    stage_id,
    clinic_id,
    designer_id,
    scanner_id,
    printer_id,
    remaining_amount,
    treatmentEnded
  
) => async (dispatch) => {
  dispatch(PatientAction.updatePatientRequest());
  const token = localStorage.getItem("jwtToken");
  try {
    await axios.put(
      `${process.env.REACT_APP_API}/api/patients/${id}`,
      {
        first_name,
        last_name,
        age,
        gender,
        teeth_model,
        number_of_aligners,
        current_aligner,
        timer_start_date,
        treatment_ended,
        payment_amount,
        status_id,
        stage_id,
        clinic_id,
        designer_id,
        scanner_id,
        printer_id,
        remaining_amount,
        treatmentEnded
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(PatientAction.updatePatientSuccess());
    dispatch(getPatient());
  } catch (e) {
    dispatch(PatientAction.updatePatientFail(e.response.data));
  }
};
export const deletePatient = (id) => async (dispatch) => {
  dispatch(PatientAction.deletePatientRequest());
  const token = localStorage.getItem("jwtToken");
  try {
    
    await axios.delete(process.env.REACT_APP_API + "/api/patients/" + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(PatientAction.deletePatientSuccess());
    dispatch(getPatient());
  } catch (e) {
    dispatch(PatientAction.deletePatientFail(e.response.data));
  }
};
export const getPatientFile = (id) => async (dispatch) => {
  const token = localStorage.getItem("jwtToken");

  try {
    dispatch(PatientAction.getPatientFileRequest());

    const res = await axios.get(process.env.REACT_APP_API + "/api/patients/files/" + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch(PatientAction.getPatientFileSuccess(res));
  } catch (error) {
    dispatch(PatientAction.getpatientFileFail(error));
  }
};



export const AddFiles = (id,files) => async (dispatch) => {
  const formdata = new FormData();
  for (let i = 0; i < files.length; i++) {
    formdata.append("files[]", files[i]);
  }
  dispatch(PatientAction.addFileRequest());
  const token = localStorage.getItem("jwtToken");
  try {
    await axios.post(
      process.env.REACT_APP_API + "/api/patients/files/" + id ,formdata,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(PatientAction.addFileSuccess());
    dispatch(getPatientFile(id));
  } catch (e) {
    dispatch(PatientAction.addFileSuccess(e.response.data));
  }
};


export const AddImage = (id,images) => async (dispatch) => {
  const formdata = new FormData();
  for (let i = 0; i < images.length; i++) {
    formdata.append("images[]", images[i]);
  }
  dispatch(PatientAction.addImageRequest());
  const token = localStorage.getItem("jwtToken");
  try {
    await axios.post(
      process.env.REACT_APP_API + "/api/patients/images/" + id ,formdata,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(PatientAction.addImageSuccess());
    dispatch(getPatientFile(id));
  } catch (e) {
    dispatch(PatientAction.addImageSuccess(e.response.data));
  }
}
export const deletePatientFile = (id, filename) => async (dispatch) => {
  dispatch(PatientAction.deletePatientRequest());
  const token = localStorage.getItem("jwtToken");

  try {
    
    await axios.delete(process.env.REACT_APP_API + "/api/patients/files/" + id + "/"+ filename, {


      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(PatientAction.deletePatientSuccess());
    dispatch(getPatientFile(id));
  } catch (e) {
    dispatch(PatientAction.deletePatientFail(e.response.data));
  }
};


export const getpatientbyid = (id) => async (dispatch) => {
  const token = localStorage.getItem("jwtToken");

  try {
    dispatch(PatientAction.patientidRequest());

    const res = await axios.get(process.env.REACT_APP_API + "/api/patients/" + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch(PatientAction.patientidSuccess(res));
  } catch (error) {
    dispatch(PatientAction.patientidFail(error));
  }
}